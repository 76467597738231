import React from 'react'
import Marquee from 'react-fast-marquee'
import { GlobeIcon, TwitterIcon } from '../../icons'
import styles from './footer.module.scss'

const Footer = (props) => {
  return (
    <div className={styles.footer}>
      <div className={styles.info}>
        <div className={styles.title}>
          <span className={styles.line}>
          For more information&nbsp;
          </span>
          <span className={styles.line}>
          about WAGYUSWAP
          </span>
        </div>
        <div className={styles.socialIcons}>
          <ul className={styles.iconList}>
            <li className={styles.item}>
              <a className={styles.link} href="#" target="_blank">
                <TwitterIcon className={styles.icon}/>
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#" target="_blank">
                <GlobeIcon className={styles.icon}/>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Marquee pauseOnHover={true} speed={100} gradientWidth={100}>
        <div>WAGYU SWAP</div>
        <div className={styles.solidLine}></div>
        <div>KOBE CLUB</div>
        <div className={styles.solidLine}></div>
        <div>A5 MEMBERSHIP</div>
        <div className={styles.solidLine}></div>
      </Marquee>
      <div className={styles.hrLine}></div>
      <div className={styles.copyright}>
        <div>© 2022 KOBE CLUB</div>
        <div>All Rights Reserved.</div>
        </div>
    </div>
  )
}

export default Footer
