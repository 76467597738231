import React from 'react'
import { combinedClasses } from '../../utils/'
import styles from './button.module.scss'

const Button = ({
  size = 'm',
  text,
  icon,
  type = 'regular', // ghost' | 'regular' | 'solid',
  onClick,
}) => {
  return (
    <div
      className={combinedClasses(
        styles.button,
        styles[size],
        styles[type],
        text ? styles.withLabel : '',
      )}
      onClick={onClick}
    >
      {text && (
        <div className={styles.buttoncontainer2}>
          <span className={styles.mas}>
            <div className={styles.text}>{text}</div>
            {icon && <span className={styles.icon}>{icon}</span>}
          </span>
          <button className={styles.view} type="button" name="Hover">
            <div className={styles.text}>{text}</div>
            {icon && <span className={styles.icon}>{icon}</span>}
          </button>
        </div>
      )}
    </div>
  )
}

export default Button
