import React from 'react'
import Content from '../content/content'
import Roadmap from '../roadmap/roadmap'
import Toolbar from '../toolbar/toolbar'
import Canvas from '../canvas/canvas'
import styles from './main.module.scss'

const Main = ({ isTrailerOpen, onTailerChange }) => {
  const [roadMapActive, setRoadMapActive] = React.useState(false);

  const onUpdateRoadMap = () => {
    if (!roadMapActive) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
    setRoadMapActive(!roadMapActive)
  }

  const onClose = () => {
    document.body.classList.remove('no-scroll');
    setRoadMapActive(false);
  }

  return (
    <>
      <Content />
      <Roadmap roadMapActive={roadMapActive} onClose={onClose} />
      {/* <Canvas /> */}
      <Toolbar onClose={onUpdateRoadMap} isTrailerOpen={isTrailerOpen} onTailerChange={onTailerChange}/>
    </>
  )
}

export default Main
