import React, { useEffect, useRef, useState } from 'react'
import styles from './page.module.scss'
import { motion, useAnimation } from 'framer-motion'
import { combinedClasses } from '../../utils'
import { useInView } from 'react-intersection-observer'

const Page = ({ className, title, subtitle, description }) => {
  const control = useAnimation()
  const [ref, inView] = useInView()

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  }

  useEffect(() => {
    if (inView) {
      control.start('visible')
    } else {
      control.start('hidden')
    }
  }, [control, inView])
  return (
    <div className={combinedClasses(styles.blockWrapper, styles[className])}>
      <motion.div
        // className={styles.blocks}
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        <div className={styles.block}>
          <h2 className={styles.title}>
            <span className={styles.line}>{title}</span>
            <span className={styles.line}>{subtitle}</span>
          </h2>
          <p className={styles.text}>{description}</p>
        </div>
      </motion.div>
    </div>
  )
}

export default Page
