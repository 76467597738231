import React from 'react'
import Button from '../button/button'
import { OpenseaIcon } from '../../icons'
import styles from './toolbar.module.scss'

const Toolbar = ({ onClose, isTrailerOpen, onTailerChange }) => {
  const handleTrailer = () => {
    onTailerChange(!isTrailerOpen)
  }

  return (
    <div className={styles.toolbar}>
      <div className={styles.left}>
        {/* <Button
          text="Our roadmap"
          icon={<img className={styles.icon} src="flag-icon.png" />}
          type="ghost"
          onClick={onClose}
        /> */}
      </div>
      <div className={styles.right}>
        <Button
          text="Play Trailer"
          type="solid"
          icon={<img className={styles.playBtn} src="play-icon.png" />}
          onClick={handleTrailer}
        />
      </div>
    </div>
  )
}

export default Toolbar
