/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/superkid_robot_copy.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.81, 170.24, 0]}>
        <group position={[-4.74, 95.86, 11.18]} rotation={[0, -0.1, 0]}>
          <group position={[-86.55, -16.92, -16.01]} rotation={[-0.43, 0.05, 0.14]}>
            <mesh geometry={nodes.microphone.geometry} material={materials['default']} position={[5.74, -30.54, -51.59]} rotation={[-2.35, -1.53, -1.96]} scale={[0.73, 1.59, 0.05]} />
          </group>
          <group position={[0.84, -70.31, -7.5]} rotation={[-0.04, 0, 0]} scale={[1.04, 0.92, 0.8]}>
            <group position={[0, -6.84, 3.69]} scale={[0.83, 0.87, 1.07]}>
              <mesh geometry={nodes.EarSocket.geometry} material={materials['default']} position={[-90.16, 77.03, -8.54]} rotation={[1.38, -1.57, 1.24]} scale={[0.93, 0.92, 1.04]} />
              <mesh geometry={nodes.InnerEar_2.geometry} material={materials['default']} position={[113.3, 76.1, -8.26]} rotation={[-1.6, -1.48, -1.57]} scale={[0.93, 0.92, 0.92]} />
              <mesh geometry={nodes.Head.geometry} material={materials['default']} position={[-0.34, 77.81, -8.06]} rotation={[3.12, 0, -Math.PI]} scale={[0.92, 0.66, 0.82]}>
                <group position={[0, -10.18, 63.55]} rotation={[-Math.PI, 0, Math.PI]} scale={[1.02, 1.27, 1.25]}>
                  <mesh geometry={nodes.Mouth.geometry} material={materials['default']} position={[0.59, -18.61, -15.89]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.8, 0.92, 0.01]}>
                    <mesh geometry={nodes.tongue.geometry} material={materials['default']} position={[18.4, 3.83, 34.54]} scale={[1.25, 1.25, 194.29]} />
                  </mesh>
                  <mesh geometry={nodes.Eyes.geometry} material={materials['default']} position={[0, 6.26, -15.61]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.95, 0.92, 0.19]} />
                  <mesh geometry={nodes.glass.geometry} material={materials['default']} position={[0, 0.93, -22.34]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.13, 1.38, 0.26]} />
                  <mesh geometry={nodes.FaceTrim_2.geometry} material={materials['default']} position={[0, -2.09, -55.42]} rotation={[-Math.PI, 0, 0]} scale={[-1, 1, 1]} />
                  <mesh geometry={nodes.FaceTrim.geometry} material={materials['default']} position={[0, 4.76, -55.42]} rotation={[-Math.PI, 0, -Math.PI]} />
                  <mesh geometry={nodes.FaceScreen.geometry} material={materials['default']} position={[0, 0.22, -1.42]} rotation={[-Math.PI, 0, Math.PI]} scale={[0.94, 0.81, 0.63]} />
                </group>
                <mesh geometry={nodes.underchinPlate.geometry} material={materials['default']} position={[0, -84.4, -1.37]} rotation={[-Math.PI, 0, Math.PI]} scale={[1, 1.4, 1.14]} />
                <mesh geometry={nodes.RearVents.geometry} material={materials['default']} position={[0, -23.17, -107.22]} rotation={[-Math.PI, 0, 0]} scale={[1, 1.4, 1.14]} />
                <mesh geometry={nodes.FloaterHoles.geometry} material={materials['default']} position={[-11.8, 98.32, -57.21]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.5, 1.02, 0.15]} />
              </mesh>
            </group>
          </group>
          <mesh geometry={nodes.InnerEar.geometry} material={materials['default']} position={[-94.49, -16.13, -13.57]} rotation={[1.75, -1.48, 1.46]} scale={[0.8, 0.74, 0.79]} />
          <mesh geometry={nodes.NeckTrim_2.geometry} material={materials['default']} position={[2.76, -71.97, -27.14]} rotation={[-1.63, -0.03, 0.09]} scale={[0.66, 0.63, 2.55]} />
          <mesh geometry={nodes.EarWings.geometry} material={materials['default']} position={[0.5, -15.89, -13.99]} rotation={[-2.79, 0, 1.57]} scale={[0.8, 0.15, 0.74]}>
            <mesh geometry={nodes.SurroundEarwings.geometry} material={materials['default']} position={[-0.74, 0, 0.37]} rotation={[0, -0.18, 0]} scale={[1, 3.74, 1]} />
          </mesh>
        </group>
        <group position={[0.39, -137.28, -17.62]}>
          <group position={[29.81, 66.33, 9.14]} rotation={[0, -0.01, 0.07]} scale={[0.81, 1, 0.95]}>
            <mesh geometry={nodes.HIPJOINT.geometry} material={materials['default']} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.74, 0.67, 0.74]}>
              <mesh geometry={nodes.THIGH.geometry} material={materials['default']} position={[-0.7, -0.45, -10.91]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[1.35, 1.35, 1.5]}>
                <mesh geometry={nodes.ROTATE_Knee.geometry} material={materials['default']} position={[-3.68, -37.91, -0.3]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1, 0.9, 1]}>
                  <mesh geometry={nodes.Calf.geometry} material={materials['default']} position={[-12.24, -0.22, 36.02]} rotation={[-Math.PI / 2, 0, 2.92]} scale={[1, 1, 1.11]}>
                    <mesh geometry={nodes.SockJoint.geometry} material={materials['default']} position={[-3.53, -0.27, -0.05]} rotation={[-0.78, -1.3, 2.45]} scale={[1.48, 1, 1]}>
                      <mesh geometry={nodes.SockAccent.geometry} material={materials['default']} position={[0.07, 9.02, -2.12]} rotation={[-3.09, 0, Math.PI]} scale={[0.8, 1, 1]} />
                      <mesh geometry={nodes.ROTATE_Foot.geometry} material={materials['default']} position={[-0.17, -13.31, 1.69]} rotation={[1.2, 0, Math.PI / 2]} scale={[0.85, 0.78, 0.85]}>
                        <mesh geometry={nodes.Shoe.geometry} material={materials['default']} position={[-0.17, -0.39, 1.69]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.18, 1.18, 0.87]}>
                          <mesh geometry={nodes.robbersole.geometry} material={materials['default']} position={[-16.29, -33.31, 0.26]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[4.87, 1.45, 0.67]} />
                          <mesh geometry={nodes.tongue_1.geometry} material={materials['default']} position={[-23.19, 0.78, -0.14]} rotation={[Math.PI / 2, -1.15, Math.PI / 2]} scale={[1.27, 0.65, 0.16]} />
                        </mesh>
                      </mesh>
                    </mesh>
                  </mesh>
                  <mesh geometry={nodes.KneeCap.geometry} material={materials['default']} position={[0.2, 12.58, 0.17]} rotation={[Math.PI / 2, 0, -2]} scale={[1, 1, 1.11]} />
                </mesh>
              </mesh>
            </mesh>
          </group>
          <group position={[-30.74, 66.33, 9.14]} rotation={[-0.01, -0.1, -0.07]} scale={[0.81, 1, 0.95]}>
            <mesh geometry={nodes.HIPJOINT_1.geometry} material={materials['default']} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.74, 0.67, 0.74]}>
              <mesh geometry={nodes.THIGH_1.geometry} material={materials['default']} position={[-0.7, -0.45, -10.91]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[1.35, 1.35, 1.5]}>
                <mesh geometry={nodes.ROTATE_Knee_1.geometry} material={materials['default']} position={[-3.68, -37.91, -0.3]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1, 0.9, 1]}>
                  <mesh geometry={nodes.Calf_1.geometry} material={materials['default']} position={[-12.24, -0.22, 36.02]} rotation={[-Math.PI / 2, 0, 2.92]} scale={[1, 1, 1.11]}>
                    <mesh geometry={nodes.SockJoint_1.geometry} material={materials['default']} position={[-3.53, -0.27, -0.05]} rotation={[-2.61, -1.14, 0.41]} scale={[1.48, 1, 1]}>
                      <mesh geometry={nodes.SockAccent_1.geometry} material={materials['default']} position={[0.07, 9.02, -2.12]} rotation={[-3.09, 0, Math.PI]} scale={[0.8, 1, 1]} />
                      <mesh geometry={nodes.ROTATE_Foot_1.geometry} material={materials['default']} position={[-0.17, -13.31, 1.69]} rotation={[1.2, 0, Math.PI / 2]} scale={[0.85, 0.78, 0.85]}>
                        <mesh geometry={nodes.Shoe_1.geometry} material={materials['default']} position={[-0.17, -0.39, 1.69]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[1.18, 1.18, 0.87]}>
                          <mesh geometry={nodes.robbersole_1.geometry} material={materials['default']} position={[-16.29, -33.31, 0.26]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={[4.87, 1.45, 0.67]} />
                          <mesh geometry={nodes.tongue_2.geometry} material={materials['default']} position={[-23.19, 0.78, -0.14]} rotation={[Math.PI / 2, -1.15, Math.PI / 2]} scale={[1.27, 0.65, 0.16]} />
                        </mesh>
                      </mesh>
                    </mesh>
                  </mesh>
                  <mesh geometry={nodes.KneeCap_1.geometry} material={materials['default']} position={[0.2, 12.58, 0.17]} rotation={[Math.PI / 2, 0, -2]} scale={[1, 1, 1.11]} />
                </mesh>
              </mesh>
            </mesh>
          </group>
        </group>
        <mesh geometry={nodes['02-Torso'].geometry} material={materials['default']} position={[0.81, -43.79, 4.53]} rotation={[3, 0, -3.12]} scale={[0.86, 1, 0.82]}>
          <group position={[0, 4.64, 2.63]} rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh geometry={nodes.Capeknot.geometry} material={materials['default']} position={[0, 31, -76.92]} rotation={[-3.07, 0, -Math.PI]} scale={[1, 1, 0.86]} />
            <mesh geometry={nodes.Sphere.geometry} material={materials['default']} position={[0, 51.43, -68.63]} rotation={[-2.89, 0, -Math.PI]} scale={[1, 0.46, 0.58]} />
            <mesh geometry={nodes.cape_1.geometry} material={materials['default']} position={[0, 54.36, 2.4]} scale={[1.3, 0.11, 1.32]} />
          </group>
          <group position={[1.11, 41.6, 6.42]} rotation={[3, 0, -3.12]} scale={[1.16, 1, 1.21]}>
            <group position={[0, -167.91, 1.9]} scale={[0.9, 1, 1]}>
              <mesh geometry={nodes.ROTATE_ShoulderRight.geometry} material={materials['default']} position={[62.46, 152.28, -6.74]} rotation={[1.49, 1.31, 3.05]} scale={[-0.84, 0.92, 0.9]}>
                <group position={[-0.05, 0.1, -12.8]} rotation={[0, Math.PI / 2, 0]} scale={[-1.11, 1.09, 1.19]}>
                  <group position={[52.57, 0, -1.14]} rotation={[0, 1.05, 0]} scale={[0.87, 1, 1]}>
                    <group position={[55.83, 0.22, 2.57]} rotation={[-0.13, 0.79, 0.17]} scale={[1.18, 1.18, 1.31]}>
                      <group position={[35.21, -11.19, 15.3]} rotation={[0, -0.09, 0]} scale={[1, 1.21, 1]}>
                        <mesh geometry={nodes.finger.geometry} material={materials['default']} position={[5.13, -0.55, 2.55]} rotation={[0, 1.28, Math.PI]} scale={[1.09, 1.01, 0.54]}>
                          <group position={[-15.52, -0.55, -1.44]} rotation={[0, 1.11, Math.PI]} scale={[1.77, 1.02, 1.06]}>
                            <mesh geometry={nodes.fingertip.geometry} material={materials['default']} position={[0.38, -0.54, -0.22]} rotation={[-Math.PI, 0.4, -Math.PI]} scale={[0.86, 0.49, 0.52]} />
                          </group>
                        </mesh>
                      </group>
                      <group position={[35.21, 1.58, 15.3]} rotation={[0, -0.09, 0]} scale={[1, 1.21, 1]}>
                        <mesh geometry={nodes.finger_1.geometry} material={materials['default']} position={[5.13, -0.55, 2.55]} rotation={[0, 1.28, Math.PI]} scale={[1.09, 1.01, 0.54]}>
                          <group position={[-15.52, -0.55, -1.44]} rotation={[0, 1.11, Math.PI]} scale={[1.77, 1.02, 1.06]}>
                            <mesh geometry={nodes.fingertip_1.geometry} material={materials['default']} position={[0.38, -0.54, -0.22]} rotation={[-Math.PI, 0.4, -Math.PI]} scale={[0.86, 0.49, 0.52]} />
                          </group>
                        </mesh>
                      </group>
                      <group position={[35.21, 14.46, 15.3]} rotation={[0, -0.09, 0]} scale={[1, 1.21, 1]}>
                        <mesh geometry={nodes.finger_2.geometry} material={materials['default']} position={[5.13, -0.55, 2.55]} rotation={[0, 1.28, Math.PI]} scale={[1.09, 1.01, 0.54]}>
                          <group position={[-15.52, -0.55, -1.44]} rotation={[0, 1.11, Math.PI]} scale={[1.77, 1.02, 1.06]}>
                            <mesh geometry={nodes.fingertip_2.geometry} material={materials['default']} position={[0.38, -0.54, -0.22]} rotation={[-Math.PI, 0.4, -Math.PI]} scale={[0.86, 0.49, 0.52]} />
                          </group>
                        </mesh>
                      </group>
                      <mesh geometry={nodes.Ellipse_3.geometry} material={materials['default']} position={[31.06, -12.78, 22.3]} rotation={[-0.02, 0.02, 0.01]} scale={[-0.58, 0.55, 0.58]} />
                      <mesh geometry={nodes.Ellipse_2.geometry} material={materials['default']} position={[30.71, 0.22, 22.23]} rotation={[-0.02, 0.02, 0.01]} scale={[-0.58, 0.55, 0.58]} />
                      <mesh geometry={nodes.Ellipse.geometry} material={materials['default']} position={[30.35, 13.83, 22.16]} rotation={[-0.02, 0.02, 0.01]} scale={[-0.58, 0.55, 0.58]} />
                      <mesh geometry={nodes.Cube.geometry} material={materials['default']} position={[-2.7, -0.48, 8.46]} rotation={[-0.06, -0.31, -0.04]} scale={[-0.94, 1.64, 4.1]} />
                      <mesh geometry={nodes.ROTATE_thumb.geometry} material={materials['default']} position={[18.85, 17.77, 13.38]} rotation={[-0.53, -0.32, 1.02]} scale={[0.8, 0.64, 0.64]} />
                      <mesh geometry={nodes.hand.geometry} material={materials['default']} position={[16.83, -0.94, -56.97]} rotation={[Math.PI, 0, 0]} />
                      <mesh geometry={nodes.handjoint.geometry} material={materials['default']} position={[2.02, -0.54, -3.15]} rotation={[Math.PI, 0, 0]} scale={0.9} />
                    </group>
                    <mesh geometry={nodes.forearm.geometry} material={materials['default']} position={[-2.14, -0.66, 1.34]} rotation={[Math.PI, 0, 0]} scale={[1.05, 1.04, 0.74]} />
                  </group>
                  <mesh geometry={nodes.Arm_2.geometry} material={materials['default']} position={[0.06, -0.73, 2.74]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 0.64, 0.4]} />
                </group>
              </mesh>
            </group>
            <group position={[0, -167.91, 1.9]} scale={[-0.9, 1, 1]}>
              <mesh geometry={nodes.ROTATE_ShoulderLEFT.geometry} material={materials['default']} position={[62.46, 152.28, -6.74]} rotation={[Math.PI / 2, 1.31, -3.13]} scale={[-0.84, 0.92, 0.9]}>
                <group position={[-0.05, 0.1, -12.8]} rotation={[0, Math.PI / 2, 0]} scale={[-1.11, 1.09, 1.19]}>
                  <group position={[52.57, 0, -1.14]} rotation={[0, 1.05, 0]} scale={[0.87, 1, 1]}>
                    <group position={[55.83, 0.22, 2.57]} rotation={[-0.13, 0.79, 0.17]} scale={[1.18, 1.18, 1.31]}>
                      <group position={[35.21, -11.19, 15.3]} rotation={[0, -0.09, 0]} scale={[1, 1.21, 1]}>
                        <mesh geometry={nodes.finger_3.geometry} material={materials['default']} position={[5.13, -0.55, 2.55]} rotation={[0, 1.28, Math.PI]} scale={[1.09, 1.01, 0.54]}>
                          <group position={[-15.52, -0.55, -1.44]} rotation={[0, 1.11, Math.PI]} scale={[1.77, 1.02, 1.06]}>
                            <mesh geometry={nodes.fingertip_3.geometry} material={materials['default']} position={[0.38, -0.54, -0.22]} rotation={[-Math.PI, 0.4, -Math.PI]} scale={[0.86, 0.49, 0.52]} />
                          </group>
                        </mesh>
                      </group>
                      <group position={[35.21, 1.58, 15.3]} rotation={[0, -0.09, 0]} scale={[1, 1.21, 1]}>
                        <mesh geometry={nodes.finger_4.geometry} material={materials['default']} position={[5.13, -0.55, 2.55]} rotation={[0, 1.28, Math.PI]} scale={[1.09, 1.01, 0.54]}>
                          <group position={[-15.52, -0.55, -1.44]} rotation={[0, 1.11, Math.PI]} scale={[1.77, 1.02, 1.06]}>
                            <mesh geometry={nodes.fingertip_4.geometry} material={materials['default']} position={[0.38, -0.54, -0.22]} rotation={[-Math.PI, 0.4, -Math.PI]} scale={[0.86, 0.49, 0.52]} />
                          </group>
                        </mesh>
                      </group>
                      <group position={[35.21, 14.46, 15.3]} rotation={[0, -0.09, 0]} scale={[1, 1.21, 1]}>
                        <mesh geometry={nodes.finger_5.geometry} material={materials['default']} position={[5.13, -0.55, 2.55]} rotation={[0, 1.28, Math.PI]} scale={[1.09, 1.01, 0.54]}>
                          <group position={[-15.52, -0.55, -1.44]} rotation={[0, 1.11, Math.PI]} scale={[1.77, 1.02, 1.06]}>
                            <mesh geometry={nodes.fingertip_5.geometry} material={materials['default']} position={[0.38, -0.54, -0.22]} rotation={[-Math.PI, 0.4, -Math.PI]} scale={[0.86, 0.49, 0.52]} />
                          </group>
                        </mesh>
                      </group>
                      <mesh geometry={nodes.Ellipse_3_1.geometry} material={materials['default']} position={[31.06, -12.78, 22.3]} rotation={[-0.02, 0.02, 0.01]} scale={[-0.58, 0.55, 0.58]} />
                      <mesh geometry={nodes.Ellipse_2_1.geometry} material={materials['default']} position={[30.71, 0.22, 22.23]} rotation={[-0.02, 0.02, 0.01]} scale={[-0.58, 0.55, 0.58]} />
                      <mesh geometry={nodes.Ellipse_1.geometry} material={materials['default']} position={[30.35, 13.83, 22.16]} rotation={[-0.02, 0.02, 0.01]} scale={[-0.58, 0.55, 0.58]} />
                      <mesh geometry={nodes.Cube_1.geometry} material={materials['default']} position={[-2.7, -0.48, 8.46]} rotation={[-0.07, -0.31, -0.04]} scale={[-0.94, 1.64, 4.11]} />
                      <mesh geometry={nodes.ROTATE_thumb_1.geometry} material={materials['default']} position={[18.85, 17.77, 13.38]} rotation={[-0.53, -0.32, 1.02]} scale={[0.8, 0.64, 0.64]} />
                      <mesh geometry={nodes.hand_1.geometry} material={materials['default']} position={[16.83, -0.94, -56.97]} rotation={[Math.PI, 0, 0]} />
                      <mesh geometry={nodes.handjoint_1.geometry} material={materials['default']} position={[2.02, -0.54, -3.15]} rotation={[Math.PI, 0, 0]} scale={0.9} />
                    </group>
                    <mesh geometry={nodes.forearm_1.geometry} material={materials['default']} position={[-2.14, -0.66, 1.34]} rotation={[Math.PI, 0, 0]} scale={[1.05, 1.04, 0.74]} />
                  </group>
                  <mesh geometry={nodes.Arm_3.geometry} material={materials['default']} position={[0.06, -0.73, 2.74]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 0.64, 0.4]} />
                </group>
              </mesh>
            </group>
          </group>
          <mesh geometry={nodes.Neck.geometry} material={materials['default']} position={[0.29, 66.37, 15.14]} rotation={[3.09, 0, 0]} scale={[0.61, 0.13, 0.61]} />
          <mesh geometry={nodes.armsocket1.geometry} material={materials['default']} position={[0, 25.52, 14.13]} rotation={[0, Math.PI / 2, 0]} scale={[1.26, 1.49, 1]}>
            <mesh geometry={nodes.armsocket1_2.geometry} material={materials['default']} rotation={[-Math.PI, 0, -Math.PI]} scale={[-1, 1, 1]}>
              <mesh geometry={nodes.armsocket2_1.geometry} material={materials['default']} />
            </mesh>
            <mesh geometry={nodes.armsocket2.geometry} material={materials['default']} />
          </mesh>
          <mesh geometry={nodes.Pants.geometry} material={materials['default']} position={[0, -15.32, 13.89]} rotation={[3.09, 0, -Math.PI]} scale={[1.16, 0.19, 1.07]}>
            <mesh geometry={nodes.WaistFlexor.geometry} material={materials['default']} position={[0, -14.56, 1.46]} rotation={[-0.14, 0, 0]} scale={[0.9, 4.73, 1.06]} />
          </mesh>
          <mesh geometry={nodes.Star_2.geometry} material={materials['default']} position={[-2.16, 19.69, -64.36]} rotation={[-0.17, 0, 0]} scale={[1.4, 1.11, 1.4]} />
          <mesh geometry={nodes.ChestPlate.geometry} material={materials['default']} position={[0, 26.41, 67.7]} scale={[2.09, 1, 0.17]}>
            <mesh geometry={nodes.Rectangle_2.geometry} material={materials['default']} position={[-4.39, -5.75, 22.65]} scale={[0.48, 0.86, 5.91]} />
            <mesh geometry={nodes.Rectangle.geometry} material={materials['default']} position={[-7.16, 3.11, 22.65]} scale={[0.48, 0.86, 5.91]} />
            <mesh geometry={nodes.Star.geometry} material={materials['default']} position={[17.22, -0.61, 21.07]} scale={[0.51, 0.84, 6.26]} />
          </mesh>
          <mesh geometry={nodes.NeckTrim.geometry} material={materials['default']} position={[0, 57.08, 16.78]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[0.75, 0.76, 1.85]} />
        </mesh>
      </group>
      <directionalLight intensity={0.7} decay={2} position={[88.54, 708.49, -329.68]} rotation={[-2.01, 0.11, 0.05]} />
      <PerspectiveCamera makeDefault={false} far={100000} near={5} fov={45} position={[-269.31, 281.17, -563.16]} rotation={[-2.91, -0.43, -3.04]} />
      <mesh geometry={nodes.Floor.geometry} material={materials['default']} position={[0, -22.37, 40.5]} rotation={[-Math.PI / 2, 0, -Math.PI]} />
    </group>
  )
}

useGLTF.preload('/superkid_robot_copy.glb')
