import React, { useEffect, useState, useRef } from 'react'
import { Triangle } from 'react-loader-spinner'
import { combinedClasses } from './utils'
import logo from './logo.svg'
import styles from './app.module.scss'
import Landing from './components/landing/landing'
import Trailer from './components/trailer/trailer'
import Footer from './components/footer/footer'
import Main from './components/main/main'
import { VideoScroll } from 'react-video-scroll'
import ReactPlayer from 'react-player'
import useWindowDimensions from './hooks/useWindowDimensions'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function App() {
  const [isTrailerOpen, setIsTrailerOpen] = useState(false)
  const [isTrailerClosing, setIsTrailerClosing] = useState(false)
  const [isLanding, setIsLanding] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [closeLanding, setCloseLanding] = useState(false)
  const [isSkipClicked, setIsSkipClicked] = useState(false)
  const { height, width } = useWindowDimensions()
  const playerRef = useRef()
  // const open

  const onTailerChange = () => {
    if (!isTrailerOpen) {
      document.body.classList.add('no-scroll')
    } else document.body.classList.remove('no-scroll')
    setIsTrailerOpen(!isTrailerOpen)
  }

  const onTailerClose = () => {
    setIsTrailerClosing(true)
    setTimeout(() => {
      setIsTrailerClosing(false)
      // setIsTrailerOpen(false)
      onTailerChange()
    }, 500)
  }

  const onSkipLanding = () => {
    setIsLoading(false)
    setIsSkipClicked(true)
    setTimeout(() => {
      setIsLanding(false)
      setCloseLanding(true)
    }, 500)
  }

  const onEndLanding = () => {
    setIsLanding(false)
    setCloseLanding(true)
  }

  const onLandingReady = () => {
    setIsLoading(false)
  }

  window.addEventListener('scroll', function () {
    if (playerRef && playerRef.current)
      // console.log('checking', window.pageYOffset / 300, window.pageYOffset)
      playerRef.current.seekTo(
        window.pageYOffset / (width < 768 ? 100 : 80),
        'seconds',
      )
  })

  return (
    <>
      {isLoading && (
        <div className={styles.loader}>
          <Triangle color="#c79d54" height={120} width={120} />
        </div>
      )}
      <Landing
        isLanding={isLanding}
        onSkipLanding={onSkipLanding}
        isSkipClicked={isSkipClicked}
        onEnd={onEndLanding}
        onReady={onLandingReady}
      />
      <div
        className={combinedClasses(
          styles.wrapper,
          isTrailerOpen ? styles['no-padding'] : '',
        )}
      >
        <ReactPlayer
          id="v0"
          ref={playerRef}
          className={styles['react-player']}
          muted={true}
          playing={false}
          controls={false}
          progressInterval={50}
          onProgress={(e) => {
            console.log('asdfsdf', e)
          }}
          url={width < 768 ? 'BG VERT.mp4' : 'MAIN BG.mp4'}
          onBufferEnd={console.log('buffer ended')}
          width="100%"
          height="100%"
        />
        {!isLanding && (
          <div className={styles.container}>
            <Trailer
              isTrailerOpen={isTrailerOpen}
              isTrailerClosing={isTrailerClosing}
              onTailerChange={onTailerClose}
            />
            {/* )} */}
            <Main
              isTrailerOpen={isTrailerOpen}
              onTailerChange={onTailerChange}
            />
          </div>
        )}
      </div>
      {!isLanding && <Footer />}
    </>
  )
}

export default App
