import React from 'react'
import Button from '../button/button'
import { OpenseaIcon } from '../../icons'
import { combinedClasses } from '../../utils'
import styles from './header.module.scss'

const Header = ({ onClose }) => {
  const [isTitleActive, setTitleActive] = React.useState(false)

  window.addEventListener('scroll', function () {
    if (window.pageYOffset > 250) {
      setTitleActive(true)
    } else {
      setTitleActive(false)
    }
  })

  return (
    <div className={styles.header}>
      <div
        id="title"
        className={combinedClasses(
          styles.left,
          isTitleActive ? styles.active : '',
        )}
      >
        KOBE CLUB
      </div>
      <div className={styles.right}>
        
        <Button
          text="view on opensea"
          icon={<OpenseaIcon />}
          onClick={() => {}}
        />
      </div>
    </div>
  )
}

export default Header
