import React, { useEffect } from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import { OpenseaIcon } from '../../icons'
import { combinedClasses } from '../../utils'
import 'react-vertical-timeline-component/style.min.css'
import styles from './roadmap.module.scss'

const Roadmap = ({ roadMapActive, onClose }) => {
  const [onTransition, setOnTransition] = React.useState(false)
  const [isClosed, setClosed] = React.useState(true)
  const [inView, setInView] = React.useState(false)

  useEffect(() =>{
    // if ( !roadMapActive && !closed ) {
    //   setTimeout(() => {
    //     setClosed(true);
    //   }, 1000);
    // }
    // else 
    //   setClosed(false);

    if (roadMapActive) {
      setClosed(false)
      setInView(true)
      setOnTransition(true)
      setTimeout(() => {
        setOnTransition(false)
      }, 500)
    }
    else {
      setOnTransition(true)
      setInView(false)
      setTimeout(() => {
        setClosed(true)
        setOnTransition(false)
      }, 500)
    }

  },[roadMapActive])

  const handleClickAway = () => {
    console.log('is closed', isClosed)
  }


  return (
    <div
      className={combinedClasses(
        styles.roadmap,
        inView ? styles.active : '',
        isClosed ? styles.closed : '',
        !roadMapActive || onTransition? styles.onTransition : ''
      )}
    >
      <div className={styles.wrapper} onClick={onClose}>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.inner}>
            <div className={styles.header}>
              <div className={styles.title}>Our Roadmap</div>
              <div className={styles.close} onClick={onClose}>
                <div className={styles.outer}>
                  <div className={styles.inner}>
                    <label>Back</label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <VerticalTimeline
                layout={'1-column-left'}
                lineColor="#c79d54"
                className={styles.timeLine}
              >
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: 'transparent',
                    WebkitBoxShadow: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    color: '#fff',
                  }}
                  iconClassName={styles.icon}
                  contentArrowStyle={{
                    borderRight: '#8b641f87',
                  }}
                  dateClassName={styles.date}
                  date="2022 - present"
                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  icon={<img className={styles.iconImg} src={'fire-black.png'} alt="kobe" />}
                >
                  <div className={styles.card}>
                    <h3 className={styles.title}>Lorem Ipsum</h3>
                    <div className={styles.bar}>
                      <div className={styles.emptybar}></div>
                      <div className={styles.filledbar}></div>
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur.
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: 'transparent',
                    WebkitBoxShadow: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    color: '#fff',
                  }}
                  iconClassName={styles.icon}
                  contentArrowStyle={{
                    borderRight: '#8b641f87',
                  }}
                  dateClassName={styles.date}
                  date="2022 - present"
                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  icon={<img className={styles.iconImg} src={'fire-black.png'} alt="kobe" />}
                >
                  <div className={styles.card}>
                    <h3 className={styles.title}>Lorem Ipsum</h3>
                    <div className={styles.bar}>
                      <div className={styles.emptybar}></div>
                      <div className={styles.filledbar}></div>
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur.
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: 'transparent',
                    WebkitBoxShadow: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    color: '#fff',
                  }}
                  iconClassName={styles.icon}
                  contentArrowStyle={{
                    borderRight: '#8b641f87',
                  }}
                  dateClassName={styles.date}
                  date="2022 - present"
                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  icon={<img className={styles.iconImg} src={'fire-black.png'} alt="kobe" />}
                >
                  <div className={styles.card}>
                    <h3 className={styles.title}>Lorem Ipsum</h3>
                    <div className={styles.bar}>
                      <div className={styles.emptybar}></div>
                      <div className={styles.filledbar}></div>
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur.
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: 'transparent',
                    WebkitBoxShadow: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    color: '#fff',
                  }}
                  iconClassName={styles.icon}
                  contentArrowStyle={{
                    borderRight: '#8b641f87',
                  }}
                  dateClassName={styles.date}
                  date="2022 - present"
                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  icon={<img className={styles.iconImg} src={'fire-black.png'} alt="kobe" />}
                >
                  <div className={styles.card}>
                    <h3 className={styles.title}>Lorem Ipsum</h3>
                    <div className={styles.bar}>
                      <div className={styles.emptybar}></div>
                      <div className={styles.filledbar}></div>
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur.
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: 'transparent',
                    WebkitBoxShadow: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    color: '#fff',
                  }}
                  iconClassName={styles.icon}
                  contentArrowStyle={{
                    borderRight: '#8b641f87',
                  }}
                  dateClassName={styles.date}
                  date="2022 - present"
                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  icon={<img className={styles.iconImg} src={'fire-black.png'} alt="kobe" />}
                >
                  <div className={styles.card}>
                    <h3 className={styles.title}>Lorem Ipsum</h3>
                    <div className={styles.bar}>
                      <div className={styles.emptybar}></div>
                      <div className={styles.filledbar}></div>
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur.
                    </div>
                  </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: 'transparent',
                    WebkitBoxShadow: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    color: '#fff',
                  }}
                  contentArrowStyle={{
                    borderRight: '#8b641f87',
                  }}
                  iconClassName={styles.icon}
                  dateClassName={styles.date}
                  date="2022 - present"
                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  icon={<img className={styles.iconImg} src={'fire-black.png'} alt="kobe" />}
                >
                  <div className={styles.card}>
                    <h3 className={styles.title}>Lorem Ipsum</h3>
                    <div className={styles.bar}>
                      <div className={styles.emptybar}></div>
                      <div className={styles.filledbar}></div>
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing.
                    </div>
                    <div className={styles.description}>
                      Lorem ipsum dolor sit amet, consectetur.
                    </div>
                  </div>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
        </div>
      {/* <div className={styles.back} onClick={onClose}></div> */}
      </div>
    </div>
  )
}

export default Roadmap
