export const SkipIcon = (props) => (
  <svg
    {...props}
    id="svg16"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="path838"
      d="m11.970157 4c.21615304-.0065055.42859174.05722608.60546875.18164062l10 7c.56695893.3982771.56695893 1.2384417 0 1.6367188l-10 7c-.71645594.50243742-1.6870945-.0925482-1.5644531-.95898438l.62256495-4.3526935a.22993709.22993709 0 0 0 -.35105482-.22655411l-8.7441664 5.5636226c-.72058403.45888501-1.6476205-.13859116-1.5273438-.984375l.83896848-5.8694368a6.9960159 6.9960159 0 0 0 0-1.9798764l-.83896848-5.8694368c-.08402533-.58999067.36332906-1.1220782.95898438-1.140625.20084813-.0061978.39890557.04825097.56835938.15625l8.7441664 5.5636226a.22993709.22993709 0 0 0 .35105482-.22655411l-.62256495-4.3526935c-.08402533-.58999067.36332906-1.1220782.95898438-1.140625z"
      font-feature-settings="normal"
      font-variant-alternates="normal"
      font-variant-caps="normal"
      font-variant-east-asian="normal"
      font-variant-ligatures="normal"
      font-variant-numeric="normal"
      font-variant-position="normal"
      font-variation-settings="normal"
      inline-size="0"
      isolation="auto"
      mix-blend-mode="normal"
      paint-order="fill markers stroke"
      shape-margin="0"
      shape-padding="0"
      solid-color="rgb(0,0,0)"
      text-decoration-color="rgb(0,0,0)"
      text-decoration-line="none"
      text-decoration-style="solid"
      text-indent="0"
      text-orientation="mixed"
      text-transform="none"
      white-space="normal"
    />
  </svg>
)
