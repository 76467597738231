import React from 'react'
import ReactPlayer from 'react-player'
import { combinedClasses } from '../../utils'
import { SkipIcon } from '../../icons'
import Button from '../button/button'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import styles from './landing.module.scss'

const Landing = ({ isLanding, onSkipLanding, isSkipClicked, onEnd, onReady }) => {
  const { height, width } = useWindowDimensions();
  return (
    <div
      className={combinedClasses(
        styles['player-wrapper'],
        !isLanding ? styles.closed : '',
      )}
    >
      <ReactPlayer
        className={styles['react-player']}
        autoPlay
        playsinline
        muted={true}
        playing={true}
        controls={false}
        url={width < 768 ? 'INTRO VERT.mp4' : 'INTRO MAIN.mp4'}
        width="100%"
        height="100%"
        playIcon={false}
        onStart={onReady}
        onEnded={onEnd}
      />
     {!isSkipClicked && isLanding && <div className={styles.right}>
        <Button
          size="s"
          text="skip intro"
          icon={<SkipIcon />}
          onClick={onSkipLanding}
        />
      </div>}
    </div>
  )
}

export default Landing
