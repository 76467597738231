import React, { createRef } from 'react'
import { combinedClasses } from '../../utils'
import styles from './trailer.module.scss'
import ReactPlayer from 'react-player'
import Modal from 'react-modal'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
    width: '100%',
    height: '100%',
    zIndex: '10',
    border: 'none',
    inset: '0px',
  },
}

const Trailer = ({ isTrailerOpen, isTrailerClosing, onTailerChange }) => {
  const [showControls, setShowControls] = React.useState(false)
  let hideControlsTimeout

  const onShowControls = () => {
    //remove existing timeout handlers
    clearTimeout(hideControlsTimeout)
    setShowControls(true)

    //set a new timeout to hide controls
    hideControlsTimeout = setTimeout(() => {
      setShowControls(false)
    }, 5000)
  }

  return (
    <Modal
      isOpen={isTrailerOpen}
      onRequestClose={onTailerChange}
      style={customStyles}
    >
      <div
        className={combinedClasses(
          styles.container,
          isTrailerClosing ? styles.closing : '',
        )}
        onMouseMove={onShowControls}
      >
        <div className={styles.close} onClick={onTailerChange}>
          <div className={styles.outer}>
            <div className={styles.inner}>
              <label>Back</label>
            </div>
          </div>
        </div>
        <div className={styles['player-wrapper']}>
          <ReactPlayer
            className={styles['react-player']}
            playing={true}
            controls={showControls}
            url={'KITCHENFT.mp4'}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </Modal>
  )
}

export default Trailer
