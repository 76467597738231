import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Header from '../header/header'
import Page from '../page/page'
import styles from './content.module.scss'
import { combinedClasses } from '../../utils'

const Content = (props) => {
  const ref = useRef(null)
  const section2Ref = useRef(null)
  const section3Ref = useRef(null)
  const [progress, setProgress] = useState(0)
  const [tween, setTween] = useState(null)

  const [isScrollActive, setIsScrollActive] = useState(false)

  window.addEventListener('scroll', function () {
    if (window.pageYOffset > 50) {
      // var element = document.getElementById("title");
      setIsScrollActive(true)
    } else {
      // var element = document.getElementById("title");
      setIsScrollActive(false)
    }
  })

  useEffect(() => {
    if (tween) return
    gsap.registerPlugin(ScrollTrigger)
    let scrollTween = gsap.to(ref.current, {
      ease: 'none',
      // backgroundColor: '#DAF7A6',
      scrollTrigger: {
        trigger: ref.current,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
        refreshPriority: 1,
        start: 'top 0%',
        end: '+=10%',
        markers: false,
        toggleActions: 'play reset play reset',
        onUpdate: (self) => {
          // setProgress((self.progress * 100) | 0)
        },
      },
    })

    gsap.to(section2Ref.current, {
      ease: 'none',
      // backgroundColor: '#DAF7A6',
      scrollTrigger: {
        trigger: section2Ref.current,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
        refreshPriority: 1,
        start: 'center 0%',
        end: 'center +=10%',
        markers: false,
        toggleActions: 'play reset play reset',
        onUpdate: (self) => {
          // setProgress((self.progress * 100) | 0)
        },
      },
    })

    gsap.to(section3Ref.current, {
      ease: 'none',
      // backgroundColor: '#DAF7A6',
      scrollTrigger: {
        trigger: section3Ref.current,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
        refreshPriority: 1,
        start: 'top 0%',
        end: '+=10%',
        markers: false,
        toggleActions: 'play reset play reset',
        onUpdate: (self) => {
          // setProgress((self.progress * 100) | 0)
        },
      },
    })

    // setTween(scrollTween)
  }, [])

  return (
    <div className={styles.content}>
      <Header />
      <div className={styles.blocks}>
        <div
          className={combinedClasses(styles.empty, styles.section07)}
          id="section07"
        >
          {!isScrollActive && (
            <a href="#section08">
              <span></span>
              <span></span>
              <span></span>
            </a>
          )}
        </div>
        <div ref={ref}>
          <Page
            title={'We cook it right'}
            // subtitle={'Lorem ipsum'}
            description={
              'After months of experiencing what our top-tier chefs can prepare with WagyuSwap, you know when we cook'
            }
          />
        </div>
        <div ref={ref}>
          <Page
            className={'left'}
            title={'Introducing the WAG Kobe Club!'}
            // subtitle={'Lorem ipsum'}
            description={
              'As we open up the doors to KitcheNFT soon, we have been working on a delectable recipe for our finest diners.'
            }
          />
        </div>
        <div ref={section2Ref}>
          <Page
            title={'A5 Premium Kitchen membership!'}
            // subtitle={'Lorem ipsum'}
            description={
              'That’s right, we’re serving up an A5 Premium Kitchen membership! With an exclusive, lifetime worth of benefits, your every craving will be met with a perfect, gourmet spread of rewards! Some of these flavors you’ll experience, you would never expect!'
            }
          />
        </div>
        <div ref={section3Ref}>
          <Page
            className={'left'}
            title={'PARTNERING WITH'}
            // subtitle={'Lorem ipsum'}
            description={
              "Imagine this: valet parking, constant multiple course meals, taste-testings, the full works - truly getting a seat at the table. The aroma is intoxicating, isn't it? Did we mention the partnership with a top-shelf DEX?"
            }
          />
        </div>
        <div ref={section3Ref}>
          <Page
            title={'WHITELIST SPOTS'}
            // subtitle={'Lorem ipsum'}
            description={
              'We have plated up 500 WAG Kobe Club membership NFTs. Our whitelist is coming soon. Trust us, you’ll want a taste.'
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Content
